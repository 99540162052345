@import 'src/styles/services';

.root {
  display: grid;
  grid-template-areas: '. logo image action .';
  grid-template-columns: 0.5fr repeat(3, 1fr) 0.5fr;
  flex: 1;
  gap: 16px;
  place-content: center;
  height: 100%;
  padding-inline: 24px;
  background: url('/assets/error/images/background.webp') no-repeat center center / cover;

  @include media-width-down($screen-sm) {
    grid-template-areas:
      '.'
      'logo'
      'image'
      'action'
      '.';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr auto 1fr 1fr;
  }
}

.logo {
  position: relative;
  grid-area: logo;
  min-height: 100px;

  @include media-width-down($screen-sm) {
    align-self: flex-end;
  }
}

.image {
  position: relative;
  grid-area: image;
  justify-self: center;

  @include media-width-down($screen-sm) {
    display: flex;
    place-content: center;
    place-items: center;
  }
}

.action {
  display: flex;
  grid-area: action;
  flex-direction: column;
  row-gap: 16px;
  align-self: center;

  @include media-width-down($screen-sm) {
    align-items: center;
    align-self: flex-start;
    width: 100%;
    margin-top: 24px;
  }
}

.text {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  color: $color-white;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @include media-width-down($screen-sm) {
    text-align: center;
  }
}

.button {
  align-self: flex-start;
  width: auto;
  padding-inline: 24px;

  @include media-width-down($screen-sm) {
    align-self: unset;
    width: 50%;
  }

  @include media-width-down($screen-xs) {
    width: 100%;
  }
}
